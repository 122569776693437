import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.outstylec)
  }, [_createVNode(_component_van_field, {
    id: $props.upfieldid,
    onFocus: $options.focused,
    onBlur: $options.unfocused,
    onKeyup: $options.clearChinese,
    autocomplete: "off",
    class: "uptextfont",
    modelValue: $options.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $options.value = $event),
    clearable: ""
  }, null, 8, ["id", "onFocus", "onBlur", "onKeyup", "modelValue"]), _createElementVNode("span", {
    class: _normalizeClass([{
      'focusBlur': $data.changeIndex == 1
    }, {
      'focusBlurTwo': $data.changeIndex == 0
    }])
  }, _toDisplayString($props.placeholderi), 3)], 2);
}