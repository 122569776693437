export default {
  name: 'InputField',
  props: ['outstyle', 'upfieldid', 'hrwidth', 'changeIndex', 'placeholder', 'modelValue', 'placeholderi'],
  emits: ['update:modelValue', 'update:outstyle'],
  data() {
    return {
      changeIndex: 0
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    outstylec: {
      get() {
        return this.outstyle;
      },
      set(outstylec) {
        this.$emit('update:outstyle', outstylec);
        return true;
      }
    }
  },
  watch: {
    value: {
      handler: function (newValue) {
        if (newValue) {
          this.changeIndex = 1;
          document.getElementById(this.upfieldid).setAttribute("placeholder", "");
        } else {
          document.getElementById(this.upfieldid).setAttribute("placeholder", this.placeholder);
        }
      }
    }
  },
  mounted() {
    if (this.value) {
      this.changeIndex = 1;
      document.getElementById(this.upfieldid).setAttribute("placeholder", "");
    }
  },
  methods: {
    clearChinese() {
      if (this.upfieldid == 'phone') {
        this.value = this.value.replace(/[^\x00-\xff]/ig, '');
      }
    },
    focused() {
      this.changeIndex = 1;
      document.getElementById(this.upfieldid).setAttribute("placeholder", "");
      if (!this.value) {
        document.getElementById(this.upfieldid).setAttribute("placeholder", this.placeholder);
      }
      if (this.upfieldid == 'upfield') {
        this.outstylec = 'frame van_kpbordering systemfont';
        this.$store.commit('setKpcodeStatus', true);
      }
      // if (this.upfieldid == 'sjh') {
      //     this.outstylec = 'frame van_kpbordering systemfont';
      //     this.$store.commit('setSjhStatus', true);
      // }
      // if (this.upfieldid == 'email') {
      //     this.outstylec = 'frame van_kpbordering systemfont';
      //     this.$store.commit('setYxStatus', true);
      // }
      if (this.upfieldid == 'taxNo' && this.$store.state.taxNoStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'compAddress' && this.$store.state.compAddressStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'bank' && this.$store.state.bankStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'phone' && this.$store.state.phoneStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'BankAddress' && this.$store.state.BankAddressStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'memoqy' && this.$store.state.memoqyStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'memogr' && this.$store.state.memogrStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'buyerNameqy' && this.$store.state.buyerNameqyStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      if (this.upfieldid == 'buyerNamegr' && this.$store.state.buyerNamegrStatus == true) {
        this.outstylec = 'frame van_kpbordering systemfont';
      }
      // if (this.upfieldid == 'sendPhone' && this.$store.state.mobileStatus == true) {
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
      // if (this.upfieldid == 'sendEmail' && this.$store.state.emailStatus == true) {
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
      // if (this.upfieldid == 'receive' && this.$store.state.receiveStatus == true) {
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
      // if (this.upfieldid == 'sendPhone') {
      //     this.$store.commit('setMobileStatus', true);
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
      // if (this.upfieldid == 'sendEmail') {
      //     this.$store.commit('setEmailStatus', true);
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
      // if (this.upfieldid == 'receive') {
      //     this.$store.commit('setReceiveStatus', true);
      //     this.outstylec = 'frame van_kpbordering systemfont';
      // }
    },
    unfocused() {
      document.getElementById(this.upfieldid).setAttribute("placeholder", "");
      if (this.value) {
        this.changeIndex = 1;
      } else if (this.upfieldid == 'buyerNameqy' && this.$store.state.isSHDR == 1) {
        this.changeIndex = 1;
      } else {
        this.changeIndex = 0;
      }
      this.outstylec = 'frame van_kpborder systemfont';
      if (this.upfieldid == 'taxNo' && this.$store.state.taxNoStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'compAddress' && this.$store.state.compAddressStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'bank' && this.$store.state.bankStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'phone' && this.$store.state.phoneStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'BankAddress' && this.$store.state.BankAddressStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'memoqy' && this.$store.state.memoqyStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'memogr' && this.$store.state.memogrStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'buyerNameqy' && this.$store.state.buyerNameqyStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      if (this.upfieldid == 'buyerNamegr' && this.$store.state.buyerNamegrStatus == false) {
        this.outstylec = 'frame van_kpbordererr systemfont';
      }
      // if (this.upfieldid == 'sendPhone' && this.$store.state.mobileStatus == false) {
      //     this.outstylec = 'frame van_kpbordererr systemfont';
      // }
      // if (this.upfieldid == 'sendEmail' && this.$store.state.emailStatus == false) {
      //     this.outstylec = 'frame van_kpbordererr systemfont';
      // }
      // if (this.upfieldid == 'receive' && this.$store.state.receiveStatus == false) {
      //     this.outstylec = 'frame van_kpbordererr systemfont';
      // }
      // if (this.upfieldid == 'sjh' && this.$store.state.sjhStatus == false) {
      //     this.outstylec = 'frame van_kpbordererr systemfont';
      // }
      // if (this.upfieldid == 'email' && this.$store.state.yxStatus == false) {
      //     this.outstylec = 'frame van_kpbordererr systemfont';
      // }
    }
  }
};